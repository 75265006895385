import { isEmpty, cloneDeep } from "lodash";
import { IOikContractDto } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";

export const convertSaveCardObject = (resultData: Partial<IOikCardElement>): Partial<IOikCardElement> => {
  return {
    name: resultData.name || "",
    shortName: resultData.shortName || "",
    id: resultData.id || "",
    index: resultData.index || "",
    code: resultData.code || "",
    address: resultData.address || "",
    description: resultData.description || "",
    medoAddress: resultData.medoAddress || "",
    medoName: resultData.medoName || "",
    contactEmail: resultData.contactEmail || "",
    medoGuid: resultData.medoGuid || "",
    //...(resultData.medoGuid ? { medoGuid: resultData.medoGuid } : {}),
    archives: resultData.archives || [],
    caseTransferStartDate: resultData.caseTransferStartDate || "",
    caseTransferEndDate: resultData.caseTransferEndDate || "",
    transferHistory: cloneDeep(resultData.transferHistory || []),
    isDeleted: !!resultData.isDeleted,
    isCloud: !!resultData.isCloud,
    cloudMedoGuid: resultData.cloudMedoGuid,
  };
};

export const convertSaveContracts = (oikContractDto: IOikContractDto[]): IOikContractDto[] => {
  return (oikContractDto || [])
    .map(({ id, ...item }) => {
      return {
        ...item,
        ...(id?.startsWith("empty") ? {} : { id }),
      };
    })
    .filter((el: Partial<IOikContractDto>) => !isEmpty(el));
};

export const convertOikSaveCardObject = (data: Partial<IOikCardElement>): Partial<IOikCardElement> => {
  const currentYearTransfer = data.transferHistory?.find((item) => new Date(item.caseTransferStartDate).getFullYear() === new Date().getFullYear());
  let resultTransferHistory;
  if (currentYearTransfer) {
    resultTransferHistory =
      data.transferHistory?.map((item) => {
        if (item.id === currentYearTransfer.id) {
          return {
            ...(item.id ? { id: item.id } : {}),
            caseTransferStartDate: data.caseTransferStartDate || "",
            caseTransferEndDate: data.caseTransferEndDate || "",
          };
        }
        return item;
      }) || [];
  } else {
    resultTransferHistory = (data.transferHistory || []).concat({
      caseTransferStartDate: data.caseTransferStartDate || "",
      caseTransferEndDate: data.caseTransferEndDate || "",
    });
  }

  const resultData: Partial<IOikCardElement> = {
    ...data,
    transferHistory: resultTransferHistory,
  };
  return convertSaveCardObject(resultData);
};
