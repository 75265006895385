






































import { defineComponent } from "@vue/composition-api";
import FormDatePicker from "@monorepo/uikit/src/components/tableCardModal/FormDatePicker.vue";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";

export default defineComponent({
  name: "HandoverSchedule",
  components: {
    FormDatePicker,
    TableSettings,
  },
  props: {
    schedule: {
      type: Array,
      required: true,
    },
  },
  // data(): IData {
  //   return {
  //     schedule: [
  //       { id: 1, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 2, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 3, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 4, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 5, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 6, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //       { id: 7, year: "2020", dateStart: "2020-07-13", dateEnd: "2020-07-13" },
  //     ],
  //   };
  // },
  computed: {
    headers(): Record<string, number | string | boolean>[] {
      return [
        {
          width: 227,
          value: "year",
          title: "Год",
        },
        {
          width: 227,
          value: "caseTransferStartDate",
          title: "Дата начала приема",
        },
        {
          width: 227,
          value: "caseTransferEndDate",
          title: "Дата окончания приема",
        },
      ];
    },
  },
});
