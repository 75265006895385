
























































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { SyncStatuses } from "@monorepo/catalog/src/views/OikView/types/syncStatuses";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      fields,
      isLoadingSync: false,
      SyncStatuses,
      attachArrow: `${environmentVariables.BASE_URL}assets/images/oik/attachArrow.svg`,
      errorIconSign: `${environmentVariables.BASE_URL}assets/images/oik/errorIconSign.svg`,
    };
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isShowAnimation", "user"]),
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_UPDATE_MODIFY);
    },
    syncStatusClass() {
      return (syncStatus: string) => {
        switch (syncStatus) {
          case SyncStatuses.PENDING:
            return "status-icon status-icon__pending";
          case SyncStatuses.ERROR:
            return "status-icon status-icon__error";
          case SyncStatuses.SUCCESS:
            return "status-icon status-icon__success";
          case SyncStatuses.REQUIRED_REFRESH:
            return "status-icon status-icon__required";
          case SyncStatuses.NEW:
            return "status-icon status-icon__new";
          default:
            return "";
        }
      };
    },
    tooltipTitle() {
      return (syncStatus: string) => {
        switch (syncStatus) {
          case SyncStatuses.PENDING:
            return "Актуализация справочника";
          case SyncStatuses.ERROR:
            return "Ошибка обновления";
          case SyncStatuses.SUCCESS:
            return "Успешное обновление";
          case SyncStatuses.REQUIRED_REFRESH:
            return "Требует обновления";
          case SyncStatuses.NEW:
            return "Новый ОИК";
          default:
            return "";
        }
      };
    },
    syncIconSrc() {
      return (syncStatus: string) => {
        switch (syncStatus) {
          case SyncStatuses.PENDING:
            return `${environmentVariables.BASE_URL}assets/images/oik/pendingIcon.svg`;
          case SyncStatuses.ERROR:
            return `${environmentVariables.BASE_URL}assets/images/oik/errorIconArrows.svg`;
          case SyncStatuses.SUCCESS:
            return `${environmentVariables.BASE_URL}assets/images/oik/successIcon.svg`;
          case SyncStatuses.REQUIRED_REFRESH:
            return `${environmentVariables.BASE_URL}assets/images/oik/requiredRefreshIcon.svg`;
          case SyncStatuses.NEW:
            return `${environmentVariables.BASE_URL}assets/images/oik/newIcon.svg`;
          default:
            return "";
        }
      };
    },
    isShowRotateAnimation(): boolean {
      return !this.isShowAnimation ? false : this.event.syncStatus === SyncStatuses.PENDING || this.event.syncStatus === SyncStatuses.ERROR;
    },
    lastRefreshDate(): string {
      return this.event.lastRefreshDate ? moment(this.event.lastRefreshDate.toString()).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  methods: {
    ...mapActions("oikCatalogView", ["synchronizeOik"]),
    async synchronize(oikCode: string) {
      try {
        this.isLoadingSync = true;
        const isSync = await this.synchronizeOik(oikCode);
        if (isSync) {
          showNotification("Запрос успешно отправлен", NOTIFICATION_STATUS.SUCCESS);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingSync = false;
      }
    },
  },
});
