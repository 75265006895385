



























import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import Vue from "vue";
import { v4 as uuid } from "uuid";
import CasesTree from "./CasesTree.vue";

export default defineComponent({
  components: {
    CasesTree,
  },
  props: {
    code: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedYear: null as number | null,
      isLoadingYears: false,
      isLoadingNomenclature: false,
      years: [],
      nomenclature: [],
      uuid: uuid(),
    };
  },
  methods: {
    ...mapActions("oikCatalogView", ["getNomenclatureYears", "getNomenclatureByYear"]),
    async handleNomenclatureYears() {
      try {
        this.isLoadingYears = true;
        this.selectedYear = null;
        this.nomenclature = [];
        const years = await this.getNomenclatureYears(this.code);
        this.years = years;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingYears = false;
      }
    },
    async handleNomenclatureByYear(year: number) {
      try {
        this.isLoadingNomenclature = true;
        this.selectedYear = year;
        const nomenclature = await this.getNomenclatureByYear({ oikCode: this.code, year });
        this.nomenclature = nomenclature;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingNomenclature = false;
        Vue.nextTick(() => {
          const element = document.getElementById("info-modal-content-wrap");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
        this.uuid = uuid();
      }
    },
  },
  watch: {
    async code(val) {
      if (!val) {
        return;
      }
      await this.handleNomenclatureYears();

      if (this.years.length) {
        await this.handleNomenclatureByYear(this.years[this.years.length - 1]);
      }
    },
  },
  async mounted() {
    if (!this.code) {
      return;
    }
    await this.handleNomenclatureYears();

    if (this.years.length) {
      await this.handleNomenclatureByYear(this.years[this.years.length - 1]);
    }
  },
});
