import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";

export const filtersElements = [
  {
    value: fields.OIK_CODE,
    title: "Код ОИК",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 268,
  },
  {
    value: fields.OIK_NAME,
    title: "Наименование ОИК",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 268,
  },
  {
    value: fields.OIK_STATUS,
    title: "Состояние",
    type: "selectStatus",
    width: 340,
  },
  {
    value: fields.OIK_ARCHIVE,
    title: "Краткое наименование архива",
    type: "autocomplete",
    showSelectedKey: "shortName",
    autocompleteActionName: "getAutocompleteByShortName",
    width: 268,
  },
  {
    value: fields.OIK_ID,
    title: "Идентификатор ОИК",
    type: "autocomplete",
    showSelectedKey: "id",
    autocompleteActionName: "getOikIdAutocompleteItems",
    width: 260,
  },
];
