







































import { defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "FormDatePicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    tooltip: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "гггг-мм-дд",
    },
    year: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    changeDate(value: string) {
      this.menu = false;
      this.$emit("change", value || "");
    },
  },
  watch: {
    menu(val) {
      val &&
        this.year &&
        this.$nextTick(() => {
          (this.$refs.picker as unknown as { inputYear?: string }).inputYear = this.year;
          (this.$refs.picker as unknown as { tableDate?: string }).tableDate = `${this.year}-${
            this.value ? moment(this.value, "YYYY-MM-DD").format("MM") : this.min ? moment(this.min, "YYYY-MM-DD").format("MM") : "01"
          }`;
        });
    },
  },
});
