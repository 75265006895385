import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор ОИК",
    tooltip: "Идентификатор ОИК в ЦХЭД",
    defaultWidth: 145,
    value: fields.OIK_ID,
    isHiddenByDefault: true,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Наименование ОИК",
    tooltip: "Полное наименование ОИК",
    defaultWidth: 240,
    value: fields.OIK_NAME,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Краткое наименование ОИК",
    tooltip: "Краткое наименование ОИК",
    defaultWidth: 220,
    value: fields.OIK_SHORT_NAME,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Статус",
    tooltip: "Статус",
    defaultWidth: 80,
    value: fields.STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
    slotName: "statusCell",
  },
  {
    text: "Код ОИК",
    tooltip: "Код ОИК",
    isLink: false,
    defaultWidth: 140,
    value: fields.OIK_CODE,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Индекс ОИК",
    tooltip: "Индекс организации по сфере деятельности",
    isLink: false,
    defaultWidth: 150,
    value: fields.OIK_INDEX,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Фонды",
    tooltip: "Фонды",
    defaultWidth: 150,
    value: fields.FUNDS_LIST,
    linkRights: [authorities.FUND_LIST],
    isLink: true,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "itemList",
  },
  {
    text: "Состояние",
    tooltip: "Состояние может принимать следующие значения: Активный, Удалённый",
    isLink: false,
    defaultWidth: 120,
    value: fields.OIK_STATUS,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата начала передачи дел",
    tooltip: "Дата начала передачи дел",
    isLink: false,
    defaultWidth: 190,
    value: fields.HANDOVER_DATE_FROM,
    sortField: "caseTransferStartDate",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Дата окончания передачи дел",
    tooltip: "Дата окончания передачи дел",
    isLink: false,
    defaultWidth: 190,
    value: fields.HANDOVER_DATE_TO,
    sortField: "caseTransferEndDate",
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  // { text: "Начало договора", defaultWidth: 200, value: "oikContractStart", isSorted: true, isHandle: true, id: uuid() },
  // { text: "Окончание договора", defaultWidth: 200, value: "oikContractEnd", isSorted: true, isHandle: true, id: uuid() },
];
