import { Module } from "vuex";
import axios from "axios";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/catalog/src/views/OikView/utils/convertFiltersToApi";
import { IOikElement } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { IOikArchiveElement, IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { elementStatuses } from "@monorepo/utils/src/variables/elementStatuses";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { IArchiveHierarchyElement } from "@monorepo/catalog/src/store/modules/archiveCatalogView";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { convertFiltersCustomToApi as convertFiltersArchiveToApi } from "@monorepo/catalog/src/views/ArchiveView/utils/convertFiltersToApi";

const archiveFilters = (): ITableFiltersObject => ({
  sort: {
    shortName: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 5000,
});

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  data: IOikElement[];
  sectionCells: ISection;
  searchTemplates: ISearchTemplate[];
  cells: string[];
  infiniteId: string;
  isOpenFilters: boolean;
  libraries: {
    archives: IOikArchiveElement[];
    funds: IFundElement[];
  };
  section?: Sections;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    [fields.OIK_CODE]: SORT_TYPE.ASC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {
    status: [elementStatuses[0].value],
  },
  initMessagesLength: 50,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_CATALOG_OIK_CSV;
    case "XLSX":
      return QUERY_PATH.GET_CATALOG_OIK_XLS;
    default:
      return QUERY_PATH.GET_CATALOG_OIK_PDF;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    infiniteId: new Date().toString(),
    sectionCells: {} as ISection,
    totalLength: 0,
    searchTemplates: [],
    cells: [],
    isOpenFilters: false,
    data: [],
    section: Sections.OIK,
    libraries: {
      archives: [],
      funds: [],
    },
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    addSort(state: ITableState, payload: Record<string, SORT_TYPE>) {
      state.filters.sort = { ...payload };
    },
    changeDataByChangeStatus(state: ITableState, payload: IOikElement) {
      state.data = state.data.map((item) => {
        if (item?.id === payload?.id) return payload;
        return item;
      });
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: IOikElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    addArchivesLib(state: ITableState, payload: IArchiveElement[]) {
      state.libraries.archives =
        payload.map((archive) => {
          return { ...archive, label: archive.shortName };
        }) || [];
    },
    addFundLib(state: ITableState, payload: IFundElement[]) {
      state.libraries.funds =
        payload.map((archive) => {
          return { ...archive, label: archive.shortName };
        }) || [];
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getNomenclatureYears(info, oikCode: string) {
      const { data } = await getQuery<number[]>(QUERY_PATH.GET_NOMENCLATURE_YEARS + `/${oikCode}`);
      if (data !== null) {
        return data.sort();
      }
      return [];
    },
    async getNomenclatureByYear(info, params: { oikCode: string; year: number }) {
      try {
        const { data: response } = await axios.post<{ data: unknown[] }>(getFullPath(QUERY_PATH.GET_NOMENCLATURE_BY_YEAR), params);
        return [response.data];
      } catch (e) {
        return [];
      }
    },
    async getOikIdAutocompleteItems(info, value: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(QUERY_PATH.GET_OIKS_AUTOCOMPLETE_ITEMS_BY_ID, { keyword: value }, false);
      if (data !== null) {
        return data.map((item: { id: number }) => ({
          ...item,
          titleValue: item.id,
        }));
      }
      return [];
    },
    async getAutocompleteByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async deleteOik({ state }, params: Record<string, unknown>) {
      try {
        const { filters } = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
        await axios.delete(getFullPath(params?.deleteAll ? `${QUERY_PATH.GET_CATALOG_OIK_LIST}/by-filter` : QUERY_PATH.GET_CATALOG_OIK_LIST), {
          params: params?.deleteAll ? { filters } : params,
          paramsSerializer,
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async restoreEntity(info, id: string | number) {
      try {
        await axios.put(getFullPath(QUERY_PATH.GET_CATALOG_OIK_LIST), { id, isDeleted: false });
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveCard(info, data: Record<string, unknown>): Promise<boolean> {
      if (data) {
        data = {
          ...data,
          archives: (data.archives as Record<string, unknown>[])?.map((archive) => {
            return { id: archive.id };
          }),
        };
      }

      try {
        await axios[!data.id ? "post" : "put"](getFullPath(QUERY_PATH.GET_CATALOG_OIK_LIST), data).catch(function (error) {
          const messagesArr = Object.values(error?.response?.data?.data || {});
          showNotification(messagesArr.length ? messagesArr.join(". ") : error?.response?.data?.message);
          return Promise.reject(error);
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveContracts(info, data: Record<string, unknown>[]): Promise<boolean> {
      try {
        await axios.post(getFullPath(QUERY_PATH.SAVE_CATALOG_OIK_CATALOG_LIST), data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getOikCardElement(info, id: string) {
      const { data } = await getQuery<IOikCardElement>(`${QUERY_PATH.GET_CATALOG_OIK_LIST}/${id}`);
      return data;
    },
    async getOikHierarchy(info, archiveIds: (number | string)[]) {
      const { data } = await getQuery<IArchiveHierarchyElement[]>(`${QUERY_PATH.GET_CATALOG_ARCHIVE_HIERARCHY}/by-ids`, { archiveIds });
      return data;
    },
    async getFundList({ commit }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      const { data } = await getQuery<IFundElement[]>(QUERY_PATH.GET_CATALOG_FUND_LIST, resultParams);
      if (data !== null) {
        commit("addFundLib", data);
      }
      return [];
    },
    async getArchivesList({ commit }) {
      const params = convertFiltersToApi(archiveFilters(), 0, convertFiltersArchiveToApi);
      const resultParams = {
        ...(params || {}),
        short: true,
      };
      const { data } = await getQuery<IArchiveElement[]>(QUERY_PATH.GET_CATALOG_ARCHIVE_LIST, resultParams);
      if (data !== null) {
        commit("addArchivesLib", data);
      }
      return [];
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IOikElement[]>(QUERY_PATH.GET_CATALOG_OIK_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
        root: true,
      });
    },
    async getAutocompleteArchiveByShortName(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_CATALOG_ARCHIVE_LIST + `/by-short-name/like`,
        { keyword: params },
        false
      );
      if (data !== null) {
        return data.map((item: { shortName: string }) => ({
          ...item,
          titleValue: item.shortName,
        }));
      }
      return [];
    },
    async synchronizeOik(info, params: string) {
      try {
        const { data } = await axios.post(`${QUERY_PATH.SYNCHRONIZE_OIK}/${params}`);
        if (!!data.code && data.message) {
          showNotification(data.message, NOTIFICATION_STATUS.ERROR);
        }
        info.commit("changeDataByChangeStatus", data.data);
        return !data.code;
      } catch (e) {
        showNotification("Ошибка отправки запроса", NOTIFICATION_STATUS.ERROR);
        return false;
      }
    },
    async synchronizeOiks(info, params: string[]) {
      try {
        const { data } = await axios.post(`${QUERY_PATH.SYNCHRONIZE_OIK}`, {}, { params: { oikCodes: params }, paramsSerializer });
        if (!!data.code && data.message) {
          showNotification(data.message, NOTIFICATION_STATUS.ERROR);
        }
        (data.data as IOikElement[]).forEach((item) => {
          info.commit("changeDataByChangeStatus", item);
        });
        return !data.code;
      } catch (e) {
        return false;
      }
    },
  },
  getters: {
    ...baseGetters,
    archivesLib(state: ITableState) {
      return state.libraries.archives;
    },
    fundsLib(state: ITableState) {
      return state.libraries.funds;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
