

























































import { cloneDeep } from "lodash";
import { defineComponent } from "@vue/composition-api";
import DatePicker from "./DatePicker.vue";

interface IContract {
  id: string;
}

interface IData {
  contracts: IContract[];
}

const emptyLineId = "empty";

export default defineComponent({
  name: "ContractsTable",
  components: {
    DatePicker,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.contracts = this.value ? cloneDeep(this.value as IContract[]) : [];
    if (this.isEdit) {
      this.addEmptyBottomLine(0);
    }
  },
  data(): IData {
    return {
      contracts: [],
    };
  },
  computed: {
    headers(): Record<string, number | string | boolean>[] {
      return [
        { width: 227, type: "text", value: "oikContractNumber", title: "№ договора", placeholder: "+ ещё договор" },
        { width: 227, type: "datepicker", value: "dateStart", title: "Начало договора", placeholder: "" },
        { width: 227, type: "datepicker", value: "dateEnd", title: "Окончание договора", placeholder: "", isNeedTrash: true },
      ];
    },
  },
  methods: {
    addEmptyBottomLine(index: number) {
      this.contracts = this.contracts.concat({ id: emptyLineId + index });
    },
    deleteRow(index: number) {
      this.contracts = this.contracts.filter((element, indexArr) => indexArr !== index);
      this.$emit("change", this.contracts);
    },
    inputChangeCb(index: number) {
      if (index === this.contracts.length - 1) {
        this.addEmptyBottomLine(index);
      }
      this.$emit("change", this.contracts);
    },
  },
});
