









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "DatePicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "гггг-мм-дд",
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    changeDate(value: string) {
      this.menu = false;
      this.$emit("change", value || "");
    },
  },
});
