import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { mask } from "@monorepo/catalog/src/views/OikView/constants/mask";
import { cloneDeep } from "lodash";

export const baseModalElements = [
  {
    id: uuid(),
    isEdited: true,
    title: "Наименование ОИК",
    tooltip: "Полное наименование ОИК",
    className: "info-modal__element_full-size",
    value: fields.OIK_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Краткое наименование ОИК",
    tooltip: "Краткое наименование ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_SHORT_NAME,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Код ОИК",
    tooltip: "Код ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_CODE,
    isRequired: true,
    mask: mask.maskOikCode,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Индекс ОИК",
    tooltip: "Индекс организации по сфере деятельности",
    className: "info-modal__element_title",
    value: fields.OIK_INDEX,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "50",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Электронная почта ОИК",
    tooltip: "Электронная почта ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_EMAIL,
    isRequired: true,
    mask: cloneDeep(standardMask.email),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Адрес ОИК",
    tooltip: "Адрес ОИК",
    className: "info-modal__element_full-size",
    value: fields.OIK_ADDRESS,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Описание ОИК",
    tooltip: "Описание ОИК",
    className: "info-modal__about info-modal__element_full-size info-modal__element_textarea",
    value: fields.OIK_DESCRIPTION,
    isRequired: true,
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
    maxLength: "20000",
    fieldType: "textarea",
  },
];

export const additionalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    tooltip: "Фонд",
    className: "info-modal__element",
    value: fields.OIK_FUNDS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Адрес ОИК",
    tooltip: "Адрес ОИК",
    activeLink: true,
    link: true,
    className: "info-modal__element",
    value: fields.OIK_ADDRESS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Описание ОИК",
    tooltip: "Описание ОИК",
    className: "info-modal__element_title",
    value: fields.OIK_DESCRIPTION,
  },
  {
    id: uuid(),
    isEdited: false,
    value: fields.DATE_REFRESH_DICT,
    title: "Дата успешного обновления справочников",
    className: "info-modal__element_title",
    tooltip: "Дата успешного обновления справочников",
  },
  {
    id: uuid(),
    isEdited: false,
    value: fields.STATUS_DICT,
    title: "Статус последней попытки обновления справочников",
    className: "info-modal__element_title",
    tooltip: "Статус последней попытки обновления справочников",
  },
  {
    id: uuid(),
    isEdited: false,
    value: fields.LAST_DATE_REFRESH_DICT,
    tooltip: "Дата последней попытки обновления справочников",
    title: "Дата последней попытки обновления справочников",
    className: "info-modal__element_title",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Состояние",
    tooltip: "Состояние может принимать следующие значения: Активный, Удалённый",
    className: "info-modal__element",
    value: fields.OIK_STATUS,
  },
  {
    id: uuid(),
    title: "Редактирование / создание ОИК",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление ОИК",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор ОИК",
    tooltip: "Идентификатор ОИК в ЦХЭД",
    className: "info-modal__element_title",
    value: fields.OIK_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Состояние",
    tooltip: "Состояние может принимать следующие значения: Активный, Удалённый",
    className: "info-modal__element_title",
    value: fields.OIK_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, сохранивший запись",
    tooltip: "Логин пользователя, сохранившего запись",
    className: "info-modal__element_title",
    value: fields.OIK_CREATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата сохранения",
    tooltip: "Дата сохранения записи",
    className: "info-modal__element_title",
    value: fields.OIK_CREATION_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    tooltip: "Логин пользователя, изменившего запись",
    className: "info-modal__element_title",
    value: fields.OIK_UPDATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последнего обновления",
    tooltip: "Дата последнего обновления записи",
    className: "info-modal__element_title",
    value: fields.OIK_UPDATE_DATE,
  },
];

export const medoElements = [
  {
    id: uuid(),
    isEdited: true,
    title: "GUID ОИК в МЭДО",
    tooltip: "GUID ОИК в МЭДО",
    className: "info-modal__element_title",
    value: fields.OIK_GUID_MEDO,
    mask: cloneDeep(standardMask.guid),
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Адрес ОИК в МЭДО",
    tooltip: "Адрес ОИК в МЭДО",
    className: "info-modal__element_title",
    value: fields.OIK_MEDO_ADDRESS,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Название ОИК в МЭДО",
    tooltip: "Название ОИК в МЭДО",
    className: "info-modal__element_full-size info-modal__element_name-oik",
    value: fields.OIK_MEDO_NAME,
  },
  {
    id: uuid(),
    isCheckbox: true,
    title: "Облачный архив",
    tooltip: "Облачный архив",
    className: "mt-1 info-modal__element_title-checkbox",
    value: fields.OIK_CLOUD_ARCHIVE,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "GUID в облачном архиве",
    tooltip: "GUID в облачном архиве",
    className: "info-modal__element_title",
    value: fields.OIK_CLOUD_MEDO_GUID,
    mask: cloneDeep(standardMask.guid),
  },
];

export const fullModalElements = [...baseModalElements, ...additionalElements, ...techElements];
