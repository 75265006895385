



















































import { defineComponent, PropType } from "@vue/composition-api";
import TableCaseTree from "./TableCaseTree.vue";
import { v4 as uuid } from "uuid";
import TreeNode from "@monorepo/uikit/src/components/tableViews/TreeNode.vue";

type section = { name: string; id: number; cases: { header: string; id: number; index: string }[]; subsections?: treeItem["sections"] };

type treeItem = {
  oikShortName: string;
  id: number;
  sections: section[];
};

const formSection = (
  sections: section[]
): {
  isTable: boolean;
  isFolder: boolean;
  title: string;
  id: string;
  cases: section["cases"];
  nomenclatureId: number;
}[] => {
  return (sections || []).map((section) => {
    return {
      isTable: true,
      isFolder: true,
      title: section.name,
      id: uuid(),
      cases: section.cases,
      nomenclatureId: section.id,
      children: formSection(section.subsections || []),
    };
  });
};

export default defineComponent({
  name: "CaseTree",
  components: {
    TreeNode,
    TableCaseTree,
  },
  props: {
    treeData: {
      type: Array as PropType<treeItem[]>,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isExpand: false,
      selected: [] as string[],
      selected2: [] as string[],
      headers: {
        oik: [],
        year: [],
        nomenclature: [],
        case: [],
      },
    };
  },
  computed: {
    resultTreeData(): {
      title: string;
      isFolder: boolean;
      id: string;
      nomenclatureId: number;
      children: ReturnType<typeof formSection>;
    }[] {
      return this.treeData.map((nomenclature: treeItem) => {
        return {
          title: "Номенклатура",
          isFolder: true,
          id: uuid(),
          nomenclatureId: nomenclature.id,
          children: formSection(nomenclature.sections),
        };
      });
    },
  },
  mounted() {
    this.toggleOpenItems();
  },
  methods: {
    handleClick(item: { id: number }) {
      const element = document.getElementById("treeItem" + item.id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    openFund({ header, item }: { header: { isLink: boolean }; item: { code: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/dictionaries/fund",
          query: {
            code: item.code,
            isOpenModal: "1",
          },
        });
      }
    },
    syncSelected(newVal: string[]) {
      this.selected2 = newVal;
    },
    syncSelected2(newVal: string[]) {
      this.selected = newVal;
    },
    toggleOpenItems(isForceTrue?: boolean) {
      this.isExpand = isForceTrue || !this.isExpand;
      (this.$refs.tree as unknown as { updateAll: (isExpand: boolean) => void }).updateAll(this.isExpand);
    },
    openInventory({ header, item }: { header: { isLink: boolean }; item: { header: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case-notes-list",
          query: {
            header: item.header,
            isOpenModal: "1",
          },
        });
      }
    },
    openCase({ header, item }: { header: { isLink: boolean }; item: { name: string } }) {
      if (header.isLink) {
        this.$router.push({
          path: "/inventory/case",
          query: {
            header: item.name,
            isOpenModal: "1",
          },
        });
      }
    },
  },
});
