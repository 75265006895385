


































import { defineComponent } from "@vue/composition-api";

type headerMin = { width: number; id: string; value: string; isTextAlignCenter?: boolean; isHandle?: boolean };

export default defineComponent({
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        { value: "index", width: 100, title: "Индекс дела" },
        { value: "header", title: "Заголовок дела" },
      ],
    };
  },
  methods: {
    setHeaderClass(header: headerMin) {
      return [
        "th",
        "pr-3 pt-0 pb-0 pl-3",
        {
          "text-left": !header.isTextAlignCenter,
          "text-center": header.isTextAlignCenter,
          "pl-0": header.value === "type",
          "pl-3": header.value !== "type",
          handle: header.isHandle,
        },
      ];
    },
  },
});
